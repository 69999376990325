<template>
  <div class="mobileCurriculum">
    <div class="header">
      <div class="username">{{ userInfo.name || "" }}</div>
      <div class="login-out" @click="loginOut">退出登录 ></div>
    </div>
    <div class="content">
      <van-tabs v-model="active" animated @click="changeTab">
        <van-tab title="课程" name="course">
          <div class="card-box" v-for="(i, _i) in courseList" :key="_i">
            <div class="title">{{ i.title }}</div>
            <div class="progress">学习进度：{{ i.progress }}%</div>
            <div class="gradient-border start" @click="into(i)">即刻学习</div>
          </div>
          <div class="empty" v-if="!courseList.length">暂无课程</div>
        </van-tab>
        <van-tab title="考试" name="examination">
          <div class="card-box" v-for="(i, _i) in examinationList" :key="_i">
            <div class="title">{{ i.title }}</div>
            <div class="progress">
              考试时间：{{ i.startDate }} {{ i.startTime }} ~ {{ i.endDate }}
              {{ i.endTime }}
            </div>
            <div class="date">考试时长：{{ i.times }}分钟</div>
            <div class="gradient-border start" @click="into(i)">
              {{ i.statusType }}
            </div>
          </div>
          <div class="empty" v-if="!examinationList.length">暂无考试</div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")) || {},
      active: "course",
      courseList: [],
      examinationList: [],
    };
  },
  created() {
    this.initCourse();
    this.initExamination();
  },
  methods: {
    changeTab(tab) {
      this.active = tab;
    },
    // 考试列表
    initExamination() {
      this.$https.get("/api/my/exam/list").then((res) => {
        if (res.success) {
          const data = res.data;
          this.examinationList = data
            .filter(
              (el) =>
                (el.status == "unstart" || el.status == "processing") &&
                !el.needCam
            )
            .map((el) => {
              return {
                ...el,
                startDate: moment(el.startDate).format("YYYY-MM-DD"),
                endDate: moment(el.endDate).format("YYYY-MM-DD"),
                statusType: changeStatus(el.status),
                showStatus:
                  el.status == "unstart" || el.status == "processing" ? 0 : 1,
              };
            });
        }
        function changeStatus(type) {
          let list = {
            unstart: "开始考试",
            processing: "继续考试",
            done: "已考完",
            graded: "已评分",
          };
          return list[type];
        }
      });
    },
    // 课程列表
    initCourse() {
      this.$https.get("/api/my/course/list").then((res) => {
        if (res.success) {
          const data = res.data;
          this.courseList = data
            .map((el) => {
              return {
                ...el,
                endTime: moment(el.endTime).format("YYYY-MM-DD"),
                startTime: moment(el.startTime).format("YYYY-MM-DD"),
                status: juedeStatus(el),
                progress: Object.keys(el.coursewareDone).length
                  ? (
                      (Object.keys(el.coursewareDone).length /
                        el.totalCoursewares) *
                      100
                    ).toFixed(2)
                  : 0,
              };
            })
            .filter((el) => el.status != 2);
        }
        // 判断课程状态，0未开始，1已经开始，2过期
        function juedeStatus(item) {
          const currentTime = moment().valueOf();
          let status;
          if (currentTime < item.startTime) {
            status = 0;
          }
          if (currentTime >= item.startTime && currentTime <= item.endTime) {
            status = 1;
          }
          if (currentTime > item.endTime) {
            status = 2;
          }
          return status;
        }
      });
    },
    into(item) {
      if (this.active == "course") {
        if (item.status != 2) {
          this.$router.replace({
            path: "/mobileCurriculum",
            query: {
              id: item.id,
            },
          });
        } else {
          this.$message.error("课程已过期");
        }
      } else {
        // if (item.needCam) {
        //   this.$message.warning("需要视频监控的考试请用电脑参加考试！")
        //   return;
        // }
        const startDate = new Date(item.startDate).getTime();
        const endDate = new Date(item.endDate).getTime();
        const timestamp = new Date().getTime();
        if (timestamp >= startDate && timestamp <= endDate) {
          this.$router.replace({
            path: "/mobileExamination",
            query: item,
          });
        } else {
          this.$message.error("考试未开始");
        }
      }
    },
    loginOut() {
      this.$https.post("/api/logout").then((res) => {
        sessionStorage.removeItem("userInfo");
        this.$router.replace({
          path: "/",
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.mobileCurriculum {
  position: relative;
  width: 100%;
  height: 100%;
  .header {
    position: absolute;
    top: 0;
    height: 30%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-image: url(../../assets/imgs/mobile_back.png);
    text-align: center;
    color: #677897;
    .username {
      margin-top: 120px;
      margin-bottom: 20px;
      font-size: 40px;
      font-weight: bold;
    }
  }
  .content {
    position: absolute;
    top: 30%;
    bottom: 0;
    width: 100%;
    overflow: auto;
    .card-box {
      position: relative;
      line-height: 2;
      background: #fff;
      margin: 20px 10px;
      border: 1px solid #e6e9f4;
      border-radius: 5px;
      padding: 12px;
      color: #b0b9ca;
      font-size: 16px;
      .title {
        text-align: left;
        color: #677897;
        font-size: 30px;
        font-size: 18px;
      }
      .start {
        display: inline-block;
        padding: 0 12px;
        position: absolute;
        // top: 36%;
        bottom: 8px;
        right: 12px;
        line-height: 36px;
      }
    }
  }
  .empty {
    line-height: 200px;
    text-align: center;
  }
}
</style>
